
import { defineComponent, onBeforeMount, reactive, toRefs, ref, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { ElMessage } from 'element-plus'
import { hotIssueApi } from '@/api/modules/hotIssue'

export default defineComponent({
  name: 'helpCenterHotIssueForm',
  props: {
    // 0:'新增', 1:'编辑', 2:'查看'
    type: {
      type: Number,
      default: null
    }
  },
  setup (props, ctx) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    // 导入mitt
    const mitter: any = inject('mitter')

    // 获取refs
    const addOrEditFormRef = ref()
    const wangeditorRef = ref()

    onBeforeMount(() => {
      const { projectId } = route.query
      // console.log('projectId', projectId)
      data.projectId = Number(projectId)
    })

    const data: any = reactive({
      projectId: null,
      pageTitle: ['新增', '编辑', '查看'],
      addOrEditForm: {
        name: '',
        project_id: '',
        answer: '',
        detail: ''
      },
      addOrEditRules: {
        name: [{ required: true, message: '请输入问题名称', trigger: 'blur' }],
        answer: [{ required: true, message: '请输入问题描述', trigger: 'blur' }]
      },
      formDisabled: false,
      determineBtnLoading: false,

      // 富文本编辑器加载完毕
      ditorInit () {
        // console.log('富文本编辑器加载完毕')
        props.type === 2 && wangeditorRef.value.Wangeditor_onDisable()
        data.formDisabled = props.type === 2

        props.type !== 0 && data.getInfo()
      },

      // 获取详情
      async getInfo () {
        const id = Number(route.query.id)
        const { res } = await hotIssueApi.getInfo({ id })
        console.log('获取详情', res)
        data.addOrEditForm = res

        // 富文本回填
        wangeditorRef.value.Wangeditor_setValue(res.detail)
      },

      // 返回
      onReturn () {
        router.go(-1)
      },

      // 提交
      onSubmit () {
        console.log('data.addOrEditForm', data.addOrEditForm)
        addOrEditFormRef.value.validate(async (valid: any) => {
          if (!valid) return

          // 提取富文本
          const ditorValue = wangeditorRef.value.Wangeditor_getValue()
          // console.log('提取富文本', ditorValue)
          if (!ditorValue) return ElMessage.error('请输入内容！')
          data.addOrEditForm.detail = ditorValue
          data.addOrEditForm.project_id = data.projectId

          try {
            data.determineBtnLoading = true
            await hotIssueApi.save(data.addOrEditForm)
            ElMessage.success(`${data.pageTitle[props.type]}成功`)
            router.go(-1)
            data.determineBtnLoading = false

            // mitt推送让列表更新
            mitter.emit('helpCenterHotIssueUpdateList')

            // 关闭标签导航栏
            store.commit('TagsView/DEL_Name_Tags', props.type === 0 ? 'helpCenterHotIssueAdd' : 'helpCenterHotIssueEdit')
          } catch (error) {
            data.determineBtnLoading = false
          }
        })
      }
    })

    return {
      ...toRefs(data),
      addOrEditFormRef,
      wangeditorRef
    }
  }
})
