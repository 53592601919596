
import { defineComponent, reactive, toRefs } from 'vue'
import Form from './form.vue'

export default defineComponent({
  name: 'helpCenterHotIssueShow',
  components: {
    Form
  },
  setup (props, ctx) {
    const data = reactive({})

    return {
      ...toRefs(data)
    }
  }
})
